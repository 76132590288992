.front_map {

	.row { 
		border: 1px solid #ddd;
		background-color: rgba($white,0.6);
	}

	@include media-breakpoint-only(lg) {
		.row { padding: 15px 30px; }
	}

	@include media-breakpoint-up(lg) {
		text-align: left;
		.row { align-items: center; }
		.map_media { @include make-col(8); padding-right: 30px; }
		.map_text { @include make-col(4); }
	}

}

	.visit-map {
		border: 5px solid $white;
		background-image: url("/_/images/maps/map-sm.webp");
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;		
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/maps/map-lg.webp");
		}
	}

	.map_text {
		h4, p {
			margin-top: 0;
			margin-bottom: 10px;
			font-family: $font-family-body;
			@include font-size(20);
			font-weight: $body-weight-bold;
			small {
				@include font-size(17);
				font-weight: $body-weight-normal;
				color: inherit;
			}
		}
		@include media-breakpoint-down(md) {
			@include make-flex;
			justify-content: space-between;
			text-align: center;
			h4, p {
				width: 48%;
			}
		}
		@include media-breakpoint-up(xl) {
			h4, p { margin-top: 20px; }
			h4:first-of-type { margin-top: 0; }
		}
	}