.address {
	line-height: 1em;
}

.banner-address {
	@include make-flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	max-width: 270px;
	margin: 15px auto;
	padding: 0;
	@include font-size(15);
	text-align: left;
	text-decoration: none;
	color: $color-text !important;
	.icon { margin-right: 8px; }
}