.block_banner {
	text-align: center;
	border-top: 5px solid $color-primary;
	border-bottom: 5px solid #ccc;
	background-color: $white;

	@include media-breakpoint-only(md) {	
		.banner_branding {
			@include make-col(5);
			order: 1;
			margin-bottom: 0 !important;
		}
		.banner_slogan { padding-top: 15px; order: 3; }
		.banner_contact {
			@include make-col(7); 
			order: 2;
		}

	}

	@include media-breakpoint-up(md) {
		.row { align-items: center; }
		.wrap { margin: 0 !important; }
		.banner_branding { text-align: left; }
		.banner_contact {
			text-align: right;
			@include make-flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
			align-items: center;
			margin-left: auto;
			> * { margin: 0 0 0 15px; }
			.contact-call { order: 2; }
			.best-rating { order: 1; }
		}
	}

	@include media-breakpoint-up(lg) {
		.wrap { @include make-col(4); }
		.banner_branding,
		.banner_slogan { text-align: left; }
		.banner_slogan { padding-left: 45px; }
	}
}

	.banner_branding {
	}

	.banner_slogan {
		@include font-size(24);
		font-family: $font-family-headings;
		line-height: 1em;
	}
		.branding-slogan {
			margin-bottom: 10px;
			line-height: 1em;
			@include media-breakpoint-up(lg) {
				span {
					display: block;
					margin-left: 35px;
				}
			}
		}

	.banner_contact {}