.g_map {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&:hover {
		cursor: pointer;
		p { opacity: 1 }
	}

	p {
		position: absolute;
		top: 45%;
		width: 100%;
		padding: 0.5em;
		@include font-size(20);
		/* font-family: $font-family-headings;
		font-weight: $font-weight-bold; */
		text-align: center;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(black, 0.4);
		background-color: theme(secondary, opac);
		color: $white;
		opacity: 0;
	}

}