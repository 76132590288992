@mixin btn-solid($bg: $color-link) {
	border: 1px solid darken($bg, 7.5%);
	background-color: darken($bg, 5%);
	color: $white;
	&:hover {
		border-color: lighten($bg, 15%);
		background-color: lighten($bg, 5%);
		color: $white;
	}
}

.btn {
	display: inline-block;
	padding: 12px 15px;
	@include font-size(16);
	font-family: $font-family-sans-serif;
	font-weight: $heading-weight-bold;
	line-height: 1em;
	text-decoration: none;
	border-radius: 4px;
	border: 1px solid;

	@include transition(all 200ms);

	&:hover {
		@include transition(all 200ms);
		cursor: pointer;
	}

	&.btn--sm {
		@include font-size(15);
		padding: 8px 12px;
	}

	&.btn--lg {
		@include font-size(17);
		padding: 16px 20px;
	}

	&.btn--wide {
		display: block;
		width: 100%;
	}

}

	.--solid-primary { @include btn-solid($color-primary); }
	.--solid-secondary { @include btn-solid($color-secondary); }
	.--solid-highlight { @include btn-solid($color-highlight); }
	.--solid-accent { @include btn-solid($color-accent); }
	.--solid-review { @include btn-solid($color-review); }
	.--solid-success { @include btn-solid($color-success); }
	.--solid-danger { @include btn-solid($color-danger); }
	.--solid-warning { @include btn-solid($color-warning); }
	.--solid-info { @include btn-solid($color-info); }




	.btn-go {
		display: inline-block;
		color: theme(primary, base);
		text-decoration: none;

		strong {
			display: inline-block;
			margin-right: 10px;
		}

		.go-wrap {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			white-space: nowrap;
		}


		.icon-go {
			@include transition(all 300ms);
			@include transform(scale(0.8));
			@include make-flex;
			align-items: center;
			justify-content: center; 
			position: relative;
			width: 50px;
			height: 48px;
			padding: 5px 3px 5px 7px;
			border-radius: 48px;
			border: 3px solid $color-secondary;
			background-color: $white;
		}
		.caret-go {
			@include transition(all 300ms);
			fill: $color-secondary;
			width: 12px;
			height: 21px;
		}
		&:hover {
			color: theme(primary, base);
			.icon-go {
				@include transition(all 300ms);
				@include transform(scale(1));
				background-color: $color-secondary;
			}
			.caret-go {
				fill: $white;
			}
		}
	}