.block {
	padding: 30px;
	.wrap:not(:last-child) { margin-bottom: 15px; }
	.wrap > *:first-child { margin-top: 0; }
	.wrap > *:last-child { margin-bottom: 0; }
	@include media-breakpoint-up(lg) {
		.wrap:not(:last-child) { margin-bottom: 0px; }
	}
}

.block_front {
	
	text-align: center;
/* 	border-top: 5px solid $white;
border-bottom: 5px solid $white; */
	//@include gdfancy-bg($body-bg);

	&.--has-bg {
		border: none;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		.container { padding: 0; }
		
		.row { margin: 0; }
		
		.wrap {
			padding: 30px;
			background-color: rgba($white, 0.85);
		}

		@include media-breakpoint-only(md) {
			.row { justify-content: center; }
		}
		
		@include media-breakpoint-up(md) {
			.wrap {
				@include make-col(10);
				@include font-size(18);
			}
		}
		
		@include media-breakpoint-up(lg) {
			padding: 45px 30px;
			.wrap { @include make-col(5); }
			&.--this-shifts .wrap { margin-left: auto; }
		}
		
		@include media-breakpoint-up(xl) {
			padding: 60px 30px;
			background-size: 1600px auto;
			background-position: center center !important;
		}

	}

	&.front_welcome {
		background-image: url("/_/images/fronts/welcome-sm.webp");
		@include media-breakpoint-up(md) { background-image: url("/_/images/fronts/welcome.webp"); }
	}
	
	&.front_about {
		background-image: url("/_/images/fronts/about-sm.webp");
		@include media-breakpoint-up(md) { background-image: url("/_/images/fronts/about.webp"); }
	}
	
	&.front_listen {
		background-image: url("/_/images/fronts/listen-sm.webp");
		background-position: left center;
		@include media-breakpoint-up(md) { background-image: url("/_/images/fronts/listen.webp"); }
	}
	
	&.front_fun {
		background-image: url("/_/images/fronts/fun-sm.webp");
		background-position: left center;
		@include media-breakpoint-up(md) { background-image: url("/_/images/fronts/fun.webp"); }
	}
	
	&.front_medication {
		background-image: url("/_/images/fronts/medications-sm.webp");
		@include media-breakpoint-up(md) { background-image: url("/_/images/fronts/medications.webp"); }
	}

	&.front_reviews,
	&.front_quote {	
		p {
			margin-bottom: 0;
			@include font-size(20);
			font-weight: $body-weight-bold;
			line-height: 1.425em;
		}

		cite {
			display: block;
			margin-top: 15px;
			font-style: normal;
			font-weight: $body-weight-bolder;
		}		
		@include media-breakpoint-up(md) {
			.row { justify-content: center; }
			.wrap { @include make-col(10); }
		}
		@include media-breakpoint-up(lg) {
			.wrap { @include make-col(8); }
		}
		@include media-breakpoint-up(xl) {
			.wrap { @include make-col(6); }
		}		
	}

	.wrap { padding: 0; }
	
}

.front_video {
	.wrap {
		max-width: 720px;
		margin: 0 auto;
	}
}