.review {

	.icon-review {
		display: block;
		height: 30px;
		width: 150px;
		margin: 0 auto;
		fill: $color-review;
	}

}