.form_quick {

	padding: 15px;
	border: 1px solid #ddd;
	background-color: rgba($white,0.6);

	.checkbox_group .form_group {
		margin-bottom: 10px;
		label { padding: 5px; }
		.checkbox { margin-top: -2px; }
	}

	@include media-breakpoint-only(md) {
		@include make-flex;
		justify-content: space-between;
		.form_section {
			width: 49%;
			padding-bottom: 0;
			&.section_submit { width: 100%; padding: 0 15px 15px; }
		}		
	}

	@include media-breakpoint-up(lg) {
		@include make-flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		.form_section {
			width: auto;
			&.section_contact_information { width: 35%; }
			&.section_request { width: 25%; }
			&.section_submit { width: 40%; }
		}
	}

	@include media-breakpoint-up(xl) {
		.section_submit .flex_wrapper {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			> div {
				display: inline-block;
				&.form_spam { width: 235px; }
			}
		}
	}

}