.site_info {
	padding: 20px 15px;
	@include font-size(14);
	line-height: 1em;
	text-align: center;
	border-top: 5px solid #ccc;
	background-color: darken($color-primary, 5%);
	color: $white;

	a { color: $white; }

	@include media-breakpoint-up(md) {
		.row { align-items: center; }		
		.wrap { @include make-col(6); }
		.info_company { text-align: left; }
		.info_copyright { text-align: right; }
	}

}