.phone {
	text-decoration: none;
	color: $color-text;
	&:hover {
		cursor: default;
		color: $color-text;
	}
}

	.contact-call {
		@include font-size(20);
		margin-bottom: 5px;
		line-height: 1em;
		.call-title {
			display: block;
		}
	}

	.contact-phone {
		@include font-size(22);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0475em;
		color: theme(secondary, base)!important;
	}

	.info-phone {
		@include font-size(22);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0475em;
	}