.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 0;
}

.logo {
	display: inline-block;
}

	.branding-logo {
		display: inline-block;
		width: 100%;
		max-width: 280px;
		@include media-breakpoint-up(md) {
			max-width: 312px;
		}
	}

	.site-info-logo {
		display: inline-block;
		width: 100%;
		max-width: 280px;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}