@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.--ai-center {
    align-items: center; }
  .flex_container.--jc-center {
    justify-content: center; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-right: 30px; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .page_contact .content_text {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .page_contact .content_contact {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .content.flip .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 2; }
  .content.flip .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; } }

body {
  min-width: 360px;
  background-color: #f5f5f5; }
  @media (min-width: 768px) {
    body {
      background-image: url("/_/images/layout/full-bloom.png"); } }
  @media (min-width: 576px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 113px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: -0.0275em;
  line-height: 1.64706em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-family: "Crimson Pro", "Times New Roman", Georgia, Times, serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0375em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    letter-spacing: -0.0125em;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  margin: 30px 0 15px;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 17px;
      font-size: 1.0625rem; } }

h4, .h4 {
  font-size: 20px;
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 24px;
      font-size: 1.5rem; } }

h3, .h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.0475em; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 32px;
      font-size: 2rem; } }

h2, .h2 {
  font-size: 32px;
  font-size: 2rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 42px;
      font-size: 2.625rem; } }

h1, .h1 {
  font-size: 42px;
  font-size: 2.625rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 48px;
      font-size: 3rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 15px;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #0b7ed1; }
  a:hover, a:focus {
    color: #1c99f3;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #bafb43; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

.list {
  margin: 0 0 15px;
  padding: 0 0 0 18px; }
  .list li:not(:last-child) {
    margin-bottom: 5px; }

.list-inline, .list-hours, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

@media (min-width: 768px) {
  .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .list-wrap.wrap-2 .list {
      padding: 0 25px; } }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

.icon-hours {
  width: 12px;
  height: 12px; }

.icon-map-marker {
  width: 23px;
  height: 30px;
  fill: #dc3545; }

.icon-stars {
  width: 82px;
  height: 18px;
  fill: #f90; }

.icon-16 {
  width: 16px;
  height: 16px; }

.icon-18 {
  width: 18px;
  height: 18px; }

.icon-64 {
  width: 64px;
  height: 64px; }

.icon-primary {
  fill: #0b7ed1; }

.icon-secondary {
  fill: #7aba04; }

.icon-highlight {
  fill: #ff9600; }

.icon-accent {
  fill: #9f20b2; }

.icon-green {
  fill: #42aa14; }

.icon-blue {
  fill: #0c84ea; }

.icon-orange {
  fill: #ff8400; }

.icon-purple {
  fill: #cc00ff; }

.icon-white {
  fill: #fff; }

.icon-facebook {
  fill: #4267b2; }

.icon-assoc {
  height: 40px; }
  .icon-assoc.assoc-blue {
    width: 207px; }
  .icon-assoc.assoc-united {
    width: 196px; }
  .icon-assoc.assoc-cigna {
    width: 122px; }
  .icon-assoc.assoc-aetna {
    width: 157px; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-responsive {
  width: 100%; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 47.5%;
    margin: 0 0 15px 30px;
    max-width: none; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 47.5%;
    margin: 0 30px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #b3bef9;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.address {
  line-height: 1em; }

.banner-address {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 270px;
  margin: 15px auto;
  padding: 0;
  font-size: 15px;
  font-size: 0.9375rem;
  text-align: left;
  text-decoration: none;
  color: #343a40 !important; }
  .banner-address .icon {
    margin-right: 8px; }

.best-rating {
  text-decoration: none;
  color: #343a40; }
  .best-rating .icon-social {
    width: 20px;
    height: 20px; }
  .best-rating .best-title {
    display: block;
    font-size: 12px;
    font-size: 0.75rem; }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn:hover {
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    cursor: pointer; }
  .btn.btn--sm {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 8px 12px; }
  .btn.btn--lg {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 16px 20px; }
  .btn.btn--wide {
    display: block;
    width: 100%; }

.--solid-primary {
  border: 1px solid #0968ad;
  background-color: #0a6fb9;
  color: #fff; }
  .--solid-primary:hover {
    border-color: #34a4f4;
    background-color: #0c8de9;
    color: #fff; }

.--solid-secondary {
  border: 1px solid #619503;
  background-color: #6aa103;
  color: #fff; }
  .--solid-secondary:hover {
    border-color: #a8fa11;
    background-color: #8ad305;
    color: #fff; }

.--solid-highlight {
  border: 1px solid #d98000;
  background-color: #e68700;
  color: #fff; }
  .--solid-highlight:hover {
    border-color: #ffb64d;
    background-color: #ffa11a;
    color: #fff; }

.--solid-accent {
  border: 1px solid #821a92;
  background-color: #8c1c9c;
  color: #fff; }
  .--solid-accent:hover {
    border-color: #c942dd;
    background-color: #b224c8;
    color: #fff; }

.--solid-review {
  border: 1px solid #d98200;
  background-color: #e68a00;
  color: #fff; }
  .--solid-review:hover {
    border-color: #ffb84d;
    background-color: #ffa31a;
    color: #fff; }

.--solid-success {
  border: 1px solid #218838;
  background-color: #23923d;
  color: #fff; }
  .--solid-success:hover {
    border-color: #48d368;
    background-color: #2dbc4e;
    color: #fff; }

.--solid-danger {
  border: 1px solid #c82333;
  background-color: #d32535;
  color: #fff; }
  .--solid-danger:hover {
    border-color: #e77681;
    background-color: #e04b59;
    color: #fff; }

.--solid-warning {
  border: 1px solid #e0a800;
  background-color: #edb100;
  color: #fff; }
  .--solid-warning:hover {
    border-color: #ffd454;
    background-color: #ffc721;
    color: #fff; }

.--solid-info {
  border: 1px solid #0169a2;
  background-color: #0172af;
  color: #fff; }
  .--solid-info:hover {
    border-color: #18adfe;
    background-color: #0192e1;
    color: #fff; }

.btn-go {
  display: inline-block;
  color: #0b7ed1;
  text-decoration: none; }
  .btn-go strong {
    display: inline-block;
    margin-right: 10px; }
  .btn-go .go-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    white-space: nowrap; }
  .btn-go .icon-go {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 48px;
    padding: 5px 3px 5px 7px;
    border-radius: 48px;
    border: 3px solid #7aba04;
    background-color: #fff; }
  .btn-go .caret-go {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    fill: #7aba04;
    width: 12px;
    height: 21px; }
  .btn-go:hover {
    color: #0b7ed1; }
    .btn-go:hover .icon-go {
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      background-color: #7aba04; }
    .btn-go:hover .caret-go {
      fill: #fff; }

.button-review {
  display: flex;
  box-sizing: border-box;
  flex-wrap: 1;
  width: 240px;
  height: 61px;
  padding: 0 8px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-decoration: none;
  border-radius: 6px;
  border: 0.075em solid #ccc;
  color: #fff !important; }
  .button-review .review-icon {
    align-self: center;
    width: 40px;
    height: 41px; }
  .button-review div {
    display: flex;
    align-items: center;
    height: 59px;
    margin-left: 8px;
    padding: 10px;
    text-align: left; }
  .button-review.--google {
    background-image: linear-gradient(to right, #fff 50%, #E94335 50%); }
    .button-review.--google div {
      background-color: #E94335; }
  .button-review.--facebook {
    background-image: linear-gradient(to right, #fff 50%, #4267B2 50%); }
    .button-review.--facebook div {
      background-color: #4267B2; }

.phone {
  text-decoration: none;
  color: #343a40; }
  .phone:hover {
    cursor: default;
    color: #343a40; }

.contact-call {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 5px;
  line-height: 1em; }
  .contact-call .call-title {
    display: block; }

.contact-phone {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0475em;
  color: #7aba04 !important; }

.info-phone {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0475em; }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  border: none;
  padding: 15px; }

.form-title {
  margin: 10px 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 5px;
  line-height: 1em;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_radio,
.form_checkbox {
  vertical-align: top;
  line-height: 1em; }
  .form_radio label,
  .form_checkbox label {
    display: block;
    clear: both;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_radio .checkbox, .form_radio .radio,
  .form_checkbox .checkbox,
  .form_checkbox .radio {
    display: block;
    margin: 0 15px 0 0;
    float: left;
    width: 20px;
    height: 20px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 68px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -3px;
    left: -38px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75); }

.form_quick {
  padding: 15px;
  border: 1px solid #ddd;
  background-color: rgba(255, 255, 255, 0.6); }
  .form_quick .checkbox_group .form_group {
    margin-bottom: 10px; }
    .form_quick .checkbox_group .form_group label {
      padding: 5px; }
    .form_quick .checkbox_group .form_group .checkbox {
      margin-top: -2px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .form_quick {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .form_quick .form_section {
        width: 49%;
        padding-bottom: 0; }
        .form_quick .form_section.section_submit {
          width: 100%;
          padding: 0 15px 15px; } }
  @media (min-width: 992px) {
    .form_quick {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-wrap: nowrap; }
      .form_quick .form_section {
        width: auto; }
        .form_quick .form_section.section_contact_information {
          width: 35%; }
        .form_quick .form_section.section_request {
          width: 25%; }
        .form_quick .form_section.section_submit {
          width: 40%; } }
  @media (min-width: 1200px) {
    .form_quick .section_submit .flex_wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center; }
      .form_quick .section_submit .flex_wrapper > div {
        display: inline-block; }
        .form_quick .section_submit .flex_wrapper > div.form_spam {
          width: 235px; } }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox > a {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  overflow: hidden;
  margin-bottom: 1%;
  border: 4px solid #fff; }
  .gallery_lightbox > a img {
    width: 100%;
    -webkit-transition: transform ease-in-out 200ms;
    -moz-transition: transform ease-in-out 200ms;
    -ms-transition: transform ease-in-out 200ms;
    -o-transition: transform ease-in-out 200ms;
    transition: transform ease-in-out 200ms; }
  .gallery_lightbox > a:hover {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    border-color: #0b7ed1; }
    .gallery_lightbox > a:hover img {
      -webkit-transition: transform ease-in-out 200ms;
      -moz-transition: transform ease-in-out 200ms;
      -ms-transition: transform ease-in-out 200ms;
      -o-transition: transform ease-in-out 200ms;
      transition: transform ease-in-out 200ms;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g6-lg > img, .g6-lg > a, .g6-lg > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-lg > img:nth-child(6n-5), .g6-lg > a:nth-child(6n-5), .g6-lg > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-lg > img:nth-child(6n), .g6-lg > a:nth-child(6n), .g6-lg > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g6-xl > img, .g6-xl > a, .g6-xl > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-xl > img:nth-child(6n-5), .g6-xl > a:nth-child(6n-5), .g6-xl > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-xl > img:nth-child(6n), .g6-xl > a:nth-child(6n), .g6-xl > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

.hours {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em; }

.contact-hours .hours-get {
  position: relative;
  display: inline-block;
  padding: 3px 5px;
  text-decoration: none;
  color: #343a40; }
  .contact-hours .hours-get:hover {
    cursor: pointer; }

.contact-hours .list-hours {
  display: none;
  width: 270px;
  margin: 0 auto 15px;
  padding: 10px;
  line-height: 1em;
  border: 1px solid #ccc; }
  .contact-hours .list-hours li {
    padding: 5px; }
    .contact-hours .list-hours li:hover {
      background-color: #c942dd; }
    .contact-hours .list-hours li.active {
      background-color: #7aba04;
      color: #fff; }
  @media (min-width: 768px) {
    .contact-hours .list-hours {
      position: absolute;
      right: -1px;
      z-index: 1;
      margin-top: 3px;
      width: 240px;
      background-color: #fff; } }

.list-hours {
  font-size: 15px;
  font-size: 0.9375rem;
  text-align: left; }
  .list-hours li:not(:last-child) {
    border-bottom: 1px dotted #ccc; }
  .list-hours li.active {
    color: #496f02; }
  .list-hours b {
    display: inline-block;
    width: 120px; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: #fff;
  background-color: transparent;
  color: #343a40; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.branding-title {
  margin: 0;
  font-weight: normal;
  line-height: 0; }

.logo {
  display: inline-block; }

.branding-logo {
  display: inline-block;
  width: 100%;
  max-width: 280px; }
  @media (min-width: 768px) {
    .branding-logo {
      max-width: 312px; } }

.site-info-logo {
  display: inline-block;
  width: 100%;
  max-width: 280px; }
  @media (min-width: 768px) {
    .site-info-logo {
      margin-bottom: 0; } }

.g_map {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .g_map:hover {
    cursor: pointer; }
    .g_map:hover p {
      opacity: 1; }
  .g_map p {
    position: absolute;
    top: 45%;
    width: 100%;
    padding: 0.5em;
    font-size: 20px;
    font-size: 1.25rem;
    /* font-family: $font-family-headings;
		font-weight: $font-weight-bold; */
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(122, 186, 4, 0.8);
    color: #fff;
    opacity: 0; }

.review .icon-review {
  display: block;
  height: 30px;
  width: 150px;
  margin: 0 auto;
  fill: #f90; }

.video_wrapper {
  padding: 4px;
  background-color: #fff; }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/icons/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.block {
  padding: 30px; }
  .block .wrap:not(:last-child) {
    margin-bottom: 15px; }
  .block .wrap > *:first-child {
    margin-top: 0; }
  .block .wrap > *:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .block .wrap:not(:last-child) {
      margin-bottom: 0px; } }

.block_front {
  text-align: center;
  /* 	border-top: 5px solid $white;
border-bottom: 5px solid $white; */ }
  .block_front.--has-bg {
    border: none;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    .block_front.--has-bg .container {
      padding: 0; }
    .block_front.--has-bg .row {
      margin: 0; }
    .block_front.--has-bg .wrap {
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.85); }
    @media (min-width: 768px) and (max-width: 991px) {
      .block_front.--has-bg .row {
        justify-content: center; } }
    @media (min-width: 768px) {
      .block_front.--has-bg .wrap {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
        font-size: 18px;
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .block_front.--has-bg {
        padding: 45px 30px; }
        .block_front.--has-bg .wrap {
          flex: 0 0 41.66667%;
          max-width: 41.66667%; }
        .block_front.--has-bg.--this-shifts .wrap {
          margin-left: auto; } }
    @media (min-width: 1200px) {
      .block_front.--has-bg {
        padding: 60px 30px;
        background-size: 1600px auto;
        background-position: center center !important; } }
  .block_front.front_welcome {
    background-image: url("/_/images/fronts/welcome-sm.webp"); }
    @media (min-width: 768px) {
      .block_front.front_welcome {
        background-image: url("/_/images/fronts/welcome.webp"); } }
  .block_front.front_about {
    background-image: url("/_/images/fronts/about-sm.webp"); }
    @media (min-width: 768px) {
      .block_front.front_about {
        background-image: url("/_/images/fronts/about.webp"); } }
  .block_front.front_listen {
    background-image: url("/_/images/fronts/listen-sm.webp");
    background-position: left center; }
    @media (min-width: 768px) {
      .block_front.front_listen {
        background-image: url("/_/images/fronts/listen.webp"); } }
  .block_front.front_fun {
    background-image: url("/_/images/fronts/fun-sm.webp");
    background-position: left center; }
    @media (min-width: 768px) {
      .block_front.front_fun {
        background-image: url("/_/images/fronts/fun.webp"); } }
  .block_front.front_medication {
    background-image: url("/_/images/fronts/medications-sm.webp"); }
    @media (min-width: 768px) {
      .block_front.front_medication {
        background-image: url("/_/images/fronts/medications.webp"); } }
  .block_front.front_reviews p, .block_front.front_quote p {
    margin-bottom: 0;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.425em; }
  .block_front.front_reviews cite, .block_front.front_quote cite {
    display: block;
    margin-top: 15px;
    font-style: normal;
    font-weight: 800; }
  @media (min-width: 768px) {
    .block_front.front_reviews .row, .block_front.front_quote .row {
      justify-content: center; }
    .block_front.front_reviews .wrap, .block_front.front_quote .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 992px) {
    .block_front.front_reviews .wrap, .block_front.front_quote .wrap {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 1200px) {
    .block_front.front_reviews .wrap, .block_front.front_quote .wrap {
      flex: 0 0 50%;
      max-width: 50%; } }
  .block_front .wrap {
    padding: 0; }

.front_video .wrap {
  max-width: 720px;
  margin: 0 auto; }

.block_banner {
  text-align: center;
  border-top: 5px solid #0b7ed1;
  border-bottom: 5px solid #ccc;
  background-color: #fff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .block_banner .banner_branding {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      order: 1;
      margin-bottom: 0 !important; }
    .block_banner .banner_slogan {
      padding-top: 15px;
      order: 3; }
    .block_banner .banner_contact {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      order: 2; } }
  @media (min-width: 768px) {
    .block_banner .row {
      align-items: center; }
    .block_banner .wrap {
      margin: 0 !important; }
    .block_banner .banner_branding {
      text-align: left; }
    .block_banner .banner_contact {
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto; }
      .block_banner .banner_contact > * {
        margin: 0 0 0 15px; }
      .block_banner .banner_contact .contact-call {
        order: 2; }
      .block_banner .banner_contact .best-rating {
        order: 1; } }
  @media (min-width: 992px) {
    .block_banner .wrap {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .block_banner .banner_branding,
    .block_banner .banner_slogan {
      text-align: left; }
    .block_banner .banner_slogan {
      padding-left: 45px; } }

.banner_slogan {
  font-size: 24px;
  font-size: 1.5rem;
  font-family: "Crimson Pro", "Times New Roman", Georgia, Times, serif;
  line-height: 1em; }

.branding-slogan {
  margin-bottom: 10px;
  line-height: 1em; }
  @media (min-width: 992px) {
    .branding-slogan span {
      display: block;
      margin-left: 35px; } }

.page_title {
  background: #7aba04;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #7aba04;
  color: #fff; }

.page-title {
  margin: 0; }

.main {
  margin-bottom: 30px; }

.site_info {
  padding: 20px 15px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-align: center;
  border-top: 5px solid #ccc;
  background-color: #0a6fb9;
  color: #fff; }
  .site_info a {
    color: #fff; }
  @media (min-width: 768px) {
    .site_info .row {
      align-items: center; }
    .site_info .wrap {
      flex: 0 0 50%;
      max-width: 50%; }
    .site_info .info_company {
      text-align: left; }
    .site_info .info_copyright {
      text-align: right; } }

.front_contact {
  text-align: left; }

.front_listen {
  border-bottom: none; }

.front_map .row {
  border: 1px solid #ddd;
  background-color: rgba(255, 255, 255, 0.6); }

@media (min-width: 992px) and (max-width: 1199px) {
  .front_map .row {
    padding: 15px 30px; } }

@media (min-width: 992px) {
  .front_map {
    text-align: left; }
    .front_map .row {
      align-items: center; }
    .front_map .map_media {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      padding-right: 30px; }
    .front_map .map_text {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.visit-map {
  border: 5px solid #fff;
  background-image: url("/_/images/maps/map-sm.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .visit-map {
      background-image: url("/_/images/maps/map-lg.webp"); } }

.map_text h4, .map_text p {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 600; }
  .map_text h4 small, .map_text p small {
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 400;
    color: inherit; }

@media (max-width: 991px) {
  .map_text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center; }
    .map_text h4, .map_text p {
      width: 48%; } }

@media (min-width: 1200px) {
  .map_text h4, .map_text p {
    margin-top: 20px; }
  .map_text h4:first-of-type {
    margin-top: 0; } }

/* @import "templates/front_welcome";
@import "templates/front_about";
@import "templates/front_reviews";
@import "templates/front_fun";
@import "templates/front_quote";
@import "templates/front_medication";
 */
.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt45 {
  margin-top: 45px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt45 {
  padding-top: 45px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Crimson Pro", "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 400 !important; }

.hw2 {
  font-weight: 400 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.ws-nw {
  white-space: nowrap !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #0b7ed1 !important; }

.color--secondary {
  color: #7aba04 !important; }

.color--highlight {
  color: #ff9600 !important; }

.color--accent {
  color: #9f20b2 !important; }

.color--link {
  color: #0b7ed1 !important; }

.color--review {
  color: #f90 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #0b7ed1 !important; }

.bg--secondary {
  background-color: #7aba04 !important; }

.bg--highlight {
  background-color: #ff9600 !important; }

.bg--link {
  background-color: #0b7ed1 !important; }

.bg--review {
  background-color: #f90 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f5f5f5 !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #0b7ed1;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #0b7ed1; }

.gdfancybg--secondary {
  background: #7aba04;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #7aba04; }

.gdfancybg--highlight {
  background: #ff9600;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #ff9600; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 15%, transparent 85%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.15) 40%, rgba(255, 255, 255, 0.15) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
