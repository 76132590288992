html {

	@include font-size(16);
	letter-spacing: -0.0275em;
	line-height: $base-line-height;
	-webkit-text-size-adjust: 100%;

	@include media-breakpoint-up(md) { @include font-size(17); }

}



body {

	font-family: $font-family-body;

}



%heading {
	margin: 30px 0 15px;
	font-family: $font-family-serif;
	font-weight: $heading-weight-bold;
	line-height: 1em;
	letter-spacing: -0.0375em;
	small {
		display: block;
		font-size: 75%;
		font-family: $font-family-sans-serif;
		font-weight: $body-weight-bold;
		letter-spacing: -0.0125em;
		line-height: 1em;
		color: #888;
	}
}

h5, .h5, %h5,
h6, .h6, %h6 {
	margin: 30px 0 15px;
	@include font-size(16);
	font-family: $font-family-body;
	font-weight: $body-weight-bold;
	@include media-breakpoint-up(lg) { @include font-size(17); }
}

h4, .h4, %h4 {
	@extend %heading;
	@include font-size(20);
	@include media-breakpoint-up(md) { @include font-size(24); }
}



h3, .h3, %h3 {
	@extend %heading;
	@include font-size(24);
	font-weight: $body-weight-bold;
	letter-spacing: -0.0475em;
	@include media-breakpoint-up(md) { @include font-size(32); }
}



h2, .h2, %h2 {
	@extend %heading;
	@include font-size(32);
	@include media-breakpoint-up(md) { @include font-size(42); }
}



h1, .h1, %h1 {
	@extend %heading;
	@include font-size(42);
	@include media-breakpoint-up(md) { @include font-size(48); }
}



p {
	margin: 0 0 $global_mb;
	&.lead {
		font-size: 118%;
		line-height: (1.5 * 1.02) * 1em;
	}
}



ul, ol {
	margin: 0;
	padding: 0;
}



b, strong { font-weight: $body-weight-bold; }



address {
	margin: 0 0 $global_mb;
	font-style: normal;
}



blockquote {

	margin: 0 0 15px;
	padding: 1em;
	font-size: 112.5%;
	line-height: 1.425em;

}
