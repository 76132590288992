.best-rating {

	text-decoration: none;
	color: $color-text;

	.icon-social {
		width: 20px;
		height: 20px;
	}

	.best-title {
		display: block;
		@include font-size(12);
	}
	
}