.hours {
	display: block;
	@include font-size(14);
	line-height: 1em;
}

.contact-hours {

	.hours-get {
		position: relative;
		display: inline-block;
		padding: 3px 5px;
		text-decoration: none;
		color: $color-text;
		&:hover { cursor: pointer; }
	}

	.list-hours {
		display: none;
		width: 270px;
		margin: 0 auto 15px;
		padding: 10px;
		line-height: 1em;
		border: 1px solid #ccc;
		li {
			padding: 5px;
			&:hover { background-color: theme(accent, light); }
			&.active {
				background-color: theme(secondary, base);
				color: $white;
			}
		}
		@include media-breakpoint-up(md) {
			position: absolute;
			right: -1px;
			z-index: 1;
			margin-top: 3px;
			width: 240px;
			background-color: $white;
		}
	}

}

	.list-hours {
		@extend %list_unstyled;
		@include font-size(15);
		text-align: left;
		li {
			&:not(:last-child) { border-bottom: 1px dotted #ccc; }
			&.active { color: theme(secondary, dark); }
		}
		b {
			display: inline-block;
			width: 120px;
		}

	}