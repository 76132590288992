.button-review {
	display: flex;
	box-sizing: border-box;
	flex-wrap: 1;
	//align-items: center;
	width: 240px;
	height: 61px;
	padding: 0 8px;
	@include font-size(14);
	line-height: 1em;
	text-decoration: none;
	border-radius: 6px;
	border: 0.075em solid #ccc;
	color: $white !important;
	.review-icon {
		align-self: center;
		width: 40px;
		height: 41px;
	}
	div {
		display: flex;
		align-items: center;
		height: 59px;
		margin-left: 8px;
		padding: 10px;
		text-align: left;
	}

	&.--google {
		background-image: linear-gradient(to right, $white 50%, #E94335 50%);
		div { background-color: #E94335; }
	}

	&.--facebook {
		background-image: linear-gradient(to right, $white 50%, #4267B2 50%);
		div { background-color: #4267B2; }
	}

}
