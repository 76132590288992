.--has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 0;
	vertical-align: middle;
}

.icon-hours { width:12px; height: 12px; }

.icon-map-marker {
	width: 23px;
	height: 30px;
	fill: $red;
}

.icon-stars {
	width: 82px; //73
	height: 18px; //16
	fill: theme(review, base);
}

.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-64 { width: 64px; height: 64px; }

.icon-primary { fill: theme(primary, base); }
.icon-secondary { fill: theme(secondary, base); }
.icon-highlight { fill: theme(highlight, base); }
.icon-accent { fill: theme(accent, base); }
.icon-green { fill: $green; }
.icon-blue { fill: $blue; }
.icon-orange { fill: $orange; }
.icon-purple { fill: $purple; }
.icon-white { fill: $white; }

.icon-facebook { fill: $color-facebook; }


.icon-assoc {
	height: 40px;
	&.assoc-blue { width: 207px; }
	&.assoc-united { width: 196px; }
	&.assoc-cigna { width: 122px; }
	&.assoc-aetna { width: 157px; }
}
